/* eslint-disable @typescript-eslint/no-empty-function */

export enum TYPE_NOTIFY {
  ADAS = 264,
  DMS = 265,
  STANDARD = 256
}

export class BaseEvent {
  // eslint-disable-next-line no-useless-constructor
  constructor (
    public type: string,
    public name: string,
    public color: string,
    public icon?: string,
    public classification?: TYPE_NOTIFY
  ) { }

  getNotName (event: any): string {
    return ''
  }

  getNotMessage (props: { event: any; report_item: any }): string {
    return ''
  }
}
export class DriverEvent extends BaseEvent {
  getNotName (): string {
    return `Alerta de ${this.name.toLowerCase()}`
  }

  getNotMessage (): string {
    return `Se ha detectado una notificación Alerta de ${this.name.toLowerCase()}`
  }
}

export const ET_JIMIIOT_DMS: { [key: string]: DriverEvent } = {
  1: new DriverEvent('1', 'Somnolencia', '#f83b8d', 'mdi-sleep', TYPE_NOTIFY.DMS),
  2: new DriverEvent('2', 'Conductor realizando llamada', '#f5942f', 'mdi-phone-lock', TYPE_NOTIFY.DMS),
  3: new DriverEvent('3', 'Conductor fumando', '#f7df3b', 'mdi-smoking-off', TYPE_NOTIFY.DMS),
  4: new DriverEvent('4', 'Conductor distraido', '#3b87f7', 'mdi-account-voice', TYPE_NOTIFY.DMS),
  5: new DriverEvent('5', 'Sin conductor en el vehiculo', '#5f5f5f', 'mdi-comment-question-outline', TYPE_NOTIFY.DMS),
  10: new DriverEvent('10', 'Captura Automatica', '#f7df3b', 'mdi-fullscreen', TYPE_NOTIFY.DMS)
} as const

export const ET_JIMIIOT_ADAS: { [key: string]: DriverEvent } = {
  1: new DriverEvent(
    '1',
    'Alarma de colisión frontal',
    '#db4040',
    'mdi-bus-alert',
    TYPE_NOTIFY.ADAS
  ),
  2: new DriverEvent(
    '2',
    'Alarma de cambio de carril',
    '#87bb10',
    'mdi-leak',
    TYPE_NOTIFY.ADAS
  ),
  3: new DriverEvent(
    '3',
    'La distancia del vehículo es demasiado cercana a la alarma delantera del vehículo.',
    '#D6D300',
    'mdi-highway',
    TYPE_NOTIFY.ADAS
  ),
  4: new DriverEvent(
    '4',
    'Alarma de colisión de peatones',
    '#900000',
    'mdi-account-multiple',
    TYPE_NOTIFY.ADAS
  ),
  5: new DriverEvent(
    '5',
    'Alarma de cambio frecuente de carril',
    '#DB9340',
    'mdi-shuffle',
    TYPE_NOTIFY.ADAS
  ),
  6: new DriverEvent(
    '6',
    'Alarma de desbordamiento de señales de tráfico',
    '#0A8FB4',
    'mdi-sign-caution',
    TYPE_NOTIFY.ADAS
  ),
  7: new DriverEvent(
    '7',
    'Alarma de obstáculos',
    '#CC005F',
    'mdi-block-helper',
    TYPE_NOTIFY.ADAS
  ),
  10: new DriverEvent(
    '10',
    'Evento de reconocimiento de señales de tráfico',
    '#81CC00',
    'mdi-sign-text',
    TYPE_NOTIFY.ADAS
  ),
  11: new DriverEvent(
    '11',
    'Evento de captura activa',
    '#A700CC',
    'mdi-car',
    TYPE_NOTIFY.ADAS
  )
} as const

export const ET_JIMIIOT_STANDARD: { [key: string]: DriverEvent } = {
  0: new DriverEvent('0', 'Alarma de emergencia (boton de panico)', '#FF0000', 'mdi-alert', TYPE_NOTIFY.STANDARD)
}

export const EVENTS_TYPES_JIMIIOT = {
  [TYPE_NOTIFY.ADAS]: ET_JIMIIOT_ADAS,
  [TYPE_NOTIFY.DMS]: ET_JIMIIOT_DMS,
  [TYPE_NOTIFY.STANDARD]: ET_JIMIIOT_STANDARD
}
