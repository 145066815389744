/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */

import Travel from '@/classes/Travels/Travel'
import objToFormData from '@/utils/objToFormData'
import { ActionContext } from 'vuex/types/index'
import {
  RESOURCES,
  PLATAFORM,
  PLATAFORM_RESOURCES
} from '../../resources/RESOURCES'
import { State } from '../state'
import ToastAction from '@/components/ToastAction.vue'
import { TravelExcelData } from '@/interfaces/TravelExcel.interface'
import datetimeToDate, { convertDate, getCurrentDate } from '@/utils/datetimeToDate'
import getRouteViaWaypoints from '@/utils/wialonSDK/getRouteViaWaypoints'
import { trycatchAsync } from '@/utils/trycatch'
import getAddress from '@/utils/osmGetAddress'
import { ESTATUS_TRAVEL } from '../../catalogs/ESTATUS_TRAVEL'
import xlsx from 'json-as-xlsx'
import { DateTime } from 'luxon'

const actions = {
  async loadFinishedTravels ({ commit, dispatch }: ActionContext<State, any>, payload: { limit: number; offset: number; } = { limit: 10, offset: 0 }) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/get_detail_travel_finalized/?limit=${payload.limit}&offset=${payload.offset}`,
        method: 'GET'
      },
      { root: true }
    )

    commit(
      'resources/SET_RESOURCES',
      {
        resources: data,
        rType: RESOURCES.TRAVELS_FINISHED
      },
      { root: true }
    )
  },
  async loadTravels ({ commit, dispatch, rootState, rootGetters }: ActionContext<State, any>) {
    let { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/get_detail_travel/',
        method: 'GET'
      },
      { root: true }
    )
    if (rootGetters['auth/isMirror']) {
      const units = rootState.travel.unitsRegisters.map((unit:any) => parseInt(unit.unit_resource_id))
      data = data.filter((travel:any) => units.includes(travel.tra_plataform_unit.id)).map((travel:any) => ({ ...travel, progress: 0, time_arrived: '', puntualidad: null }))
    }
    commit(
      'resources/SET_RESOURCES',
      {
        resources: data,
        rType: RESOURCES.TRAVELS
      },
      { root: true }
    )
  },
  async loadFinishTravels ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/get_travel_finish/',
        method: 'GET'
      },
      { root: true }
    )

    commit(
      'resources/SET_RESOURCES',
      {
        resources: data,
        rType: RESOURCES.TRAVEL_FINISH_COUNT
      },
      { root: true }
    )
  },
  async finishTravel (
    { commit, dispatch, rootState }: ActionContext<State, any>,
    travel: any
  ) {
    await dispatch(
      'app/axios',
      {
        url: '/apis/finalized_travel/',
        method: 'POST',
        data: { id_travel: travel.travel_id }
      },
      { root: true }
    )

    const index = rootState.resources[RESOURCES.TRAVELS].findIndex(
      (tra: { travel_id: number }) => tra.travel_id === travel.travel_id
    )

    if (rootState.resources[RESOURCES.TRAVELS][index]) {
      commit(
        'resources/REMOVE_RESOURCE',
        {
          index,
          rType: RESOURCES.TRAVELS
        },
        { root: true }
      )
      const now = DateTime.local()
      const timezoneOffsetHours = now.offset / 60
      const content = {
        component: ToastAction,
        props: {
          message: `Viaje  "${travel.tra_name}" ha finalizado`,
          btnText: 'Ver PDF',
          btnAction: () => {
            window.open(`${process.env.VUE_APP_BASE_URL || ''}/apis/travel_pdf_view/${travel.travel_id}/${timezoneOffsetHours}`, '_blank')
          }
        }
      }

      await dispatch(
        'app/notification',
        {
          content: content,
          type: 'success',
          timeout: false
        },
        { root: true }
      )

      await dispatch('app/playSound', { type: 'done' }, { root: true })
      await dispatch('loadFinishTravels')

      // rootState.resources[RESOURCES.TRAVEL_FINISH_COUNT].total += 1
    }

    await dispatch('loadTravels')
  },
  async loadTravelTypes ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/get_travel_type/',
        method: 'GET'
      },
      { root: true }
    )

    commit(
      'resources/SET_RESOURCES',
      {
        resources: data,
        rType: RESOURCES.TRAVEL_TYPES
      },
      { root: true }
    )
  },
  async updateLocksTravel (
    { dispatch }: ActionContext<State, any>,
    payload: any
  ) {
    await dispatch(
      'app/axios',
      {
        url: '/apis/update_locks_travel/',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    await dispatch('updateLocks', payload)
  },
  async createMany ({ dispatch }: ActionContext<State, any>, travels: any) {
    try {
      const respon = await dispatch(
        'app/axios',
        {
          url: '/apis/create_travel_resource_many/',
          method: 'POST',
          data: travels
        },
        { root: true }
      )
      // await dispatch('susbscribeAndVerify', travel)
      // await dispatch('loadTravels')
      // await dispatch('getWialonResourcesIdsAndUnits')

      // await Promise.all(travels.map((travel: any) => {
      //   dispatch('susbscribeAndVerify', travel)
      //   return dispatch(
      //     'app/axios',
      //     {
      //       url: '/apis/create_travel_resource_many/',
      //       method: 'POST',
      //       data: travel
      //     },
      //     { root: true }
      //   )
      // }))
      await dispatch('loadTravels')
      await dispatch('getWialonResourcesIdsAndUnits')
    } catch (error) {
      // @ts-ignore
      console.log(error.response)
      // @ts-ignore
      throw Error(error.response.data.message)
    }
    // await dispatch('addNewResourceNotificationUnit', data.data.data)
  },
  async createTravelsByExcel (
    { rootState, dispatch }: ActionContext<State, any>,
    sheetJson: TravelExcelData[]
  ) {
    const resources: any[] = rootState.resources.PLATAFORM[PLATAFORM.WIALON][PLATAFORM_RESOURCES.RESOURCES]
    const units: any[] = rootState.resources.PLATAFORM[PLATAFORM.WIALON][PLATAFORM_RESOURCES.UNITS]
    const clients = rootState.resources[RESOURCES.CLIENTS]
    const inventories = rootState.resources[RESOURCES.TRAVELS_INV]

    const travelsPayload = await Promise.all(sheetJson.map(async data => {
      const tra_name = data.nombre || `Viaje ${data.linea_transporte} - ${data.unidad}`

      const transport_line_resource_id = resources.find(obj => obj.$$user_name.toLowerCase().trim() === data.linea_transporte.toLowerCase().trim())?._id
      if (!transport_line_resource_id) throw Error(`Linea de transporte no encontrada ${data.linea_transporte}`)

      const resource = rootState.resources.PLATAFORM[PLATAFORM.WIALON][PLATAFORM_RESOURCES.RESOURCES].find((res: { _id: any }) => res._id === transport_line_resource_id)
      const geofences: any[] = Object.values(resource.getZones())
      const geofencesConsole: any[] = rootState.geofences.geofences
      const drivers: any[] = Object.values(resource.getDrivers())

      const tra_drivers = data.conductores.split(',').map(name => drivers.find(drv => drv.n.toLowerCase().trim() === name.toLowerCase().trim())).filter(drv => drv).map(drv => ({ id: drv.id, name: drv.n, phone: drv.p }))
      if (!tra_drivers.length) throw Error(`No se encontraron conductores en el viaje ${tra_name}`)

      const tra_plataform_unit = (() => {
        const unit = units.find(obj => obj.$$user_name.toLowerCase().trim() === data.unidad.toLowerCase().trim())
        if (!unit) throw Error(`No se encontro la unidad ${data.unidad}`)

        return {
          id: unit?._id, name: unit?.$$user_name
        }
      })()
      const tra_plataform_backrests = data.respaldos.split(',').map(name => units.find(unit => unit.$$user_name.toLowerCase().trim() === name.toLowerCase().trim())).filter(unit => unit).map(unit => ({ id: unit._id, name: unit.$$user_name }))
      if (!tra_plataform_backrests.length) throw Error(`No se encontraron respaldos del viaje ${tra_name}`)

      return {
        tra_name,
        tra_price_km: data.precio_por_kilometro || 0,
        transport_line_resource_id,
        transport_line_resource_name: data.linea_transporte,
        tra_drivers,
        tra_plataform_unit,
        tra_plataform_backrests,
        tvl_destinations: await Promise.all(data.destinos.map(async destino => {
          const tvd_fk_client = {
            userID: clients.find((obj: { userName: string }) => obj.userName.toLowerCase().trim() === destino.cliente.toLowerCase().trim()) ?? null
          }
          // PREGUNTAR IF, CUAL SERIA EL TIPO DE SISTEMA DE GEOCERCAS // IMPLEMENTAR EN EL EXCEL UN DROPDOWN
          // POR GEOCERCAS DE WIALON >>>>
          // GEOCERCAS CREADAS EN LA CONSOLA >>>>
          // COORDENADAS DE REFERENCIA >>>>
          // AGREGAR DOS CAMPOS A LA TABLA DE DESTINATION COMO || geofence_destination_excel geofence_origin_excel,  setearle un name automatico
          // FALTA AGREGAR VALIDACIONES
          const type = destino.tipo_geocerca
          let tvd_plataform_route_origin: any = null
          let tvd_plataform_route_destination: any = null
          if (type === 'Geocercas de Wialon') {
            tvd_plataform_route_origin = (() => {
              const geofence = geofences.find(obj => destino.geo_origen.toLowerCase().trim() === obj.n.toLowerCase().trim())
              if (!geofence) throw Error(`No se encontro la geocerca de origen ${destino.geo_origen} en Geocercas de la Wialon`)
              return { id: geofence.id, name: geofence.n, b: geofence.b }
            })()
            tvd_plataform_route_destination = (() => {
              const geofence = geofences.find(obj => destino.geo_destino.toLowerCase().trim() === obj.n.toLowerCase().trim())
              if (!geofence) throw Error(`No se encontro la geocerca de destino ${destino.geo_destino}  en Geocercas de Wialon`)
              return { id: geofence.id, name: geofence.n, b: geofence.b }
            })()
          } else if (type === 'Geocercas de consola') {
            tvd_plataform_route_origin = (() => {
              const geofence = geofencesConsole.find(obj => destino.geo_origen.toLowerCase().trim() === obj.n.toLowerCase().trim())
              if (!geofence) throw Error(`No se encontro la geocerca de origen ${destino.geo_origen}  en Geocercas de la Consola`)
              return { id: geofence.id, name: geofence.n, b: geofence.b }
            })()
            tvd_plataform_route_destination = (() => {
              const geofence = geofencesConsole.find(obj => destino.geo_destino.toLowerCase().trim() === obj.n.toLowerCase().trim())
              if (!geofence) throw Error(`No se encontro la geocerca de destino ${destino.geo_destino} en Geocercas de la Consola`)
              return { id: geofence.id, name: geofence.n, b: geofence.b }
            })()
          } else if (type === 'Coordenadas') {
            const [latOrigen, lngOrigen] = destino.geo_origen.trim().split(',')
            const [latDestino, lngDestino] = destino.geo_destino.trim().split(',')
            const description = { descriptionOrigen: '', descriptionDestino: '' }
            try {
              const { display_name: descriptionOrigen } = await getAddress(parseFloat(latOrigen), parseFloat(lngOrigen))
              const { display_name: descriptionDestino } = await getAddress(parseFloat(latDestino), parseFloat(lngOrigen))
              description.descriptionOrigen = descriptionOrigen
              description.descriptionDestino = descriptionDestino
            } catch (error) {
              console.error(error)
            }
            const nameO = `Origen - ${destino.folio_destino}`
            const nameD = `Destino - ${destino.folio_destino}`
            tvd_plataform_route_origin = { n: nameO, name: nameO, geo_name: nameO, b: { cen_x: lngOrigen, cen_y: latOrigen }, w: 100, geo_description: description.descriptionOrigen }
            tvd_plataform_route_destination = { n: nameD, name: nameD, geo_name: nameD, b: { cen_x: lngDestino, cen_y: latDestino }, w: 100, geo_description: description.descriptionDestino }
          }
          const tvd_estimated_leave_date = convertDate(destino.fecha_de_salida)
          const tvd_route = await trycatchAsync(() => getRouteViaWaypoints({
            lat: tvd_plataform_route_origin.b?.cen_y,
            lon: tvd_plataform_route_origin.b?.cen_x
          }, {
            lat: tvd_plataform_route_destination.b?.cen_y,
            lon: tvd_plataform_route_destination.b?.cen_x
          }), null)
          // delete tvd_plataform_route_origin.b
          // delete tvd_plataform_route_destination.b
          const tvd_estimated_arrival_date = convertDate(destino.fecha_de_llegada)
          const tvl_inventory = destino.inventario.split(',').map(obj => {
            const [tipo, tin_amount] = obj.split(':')
            const inv = inventories.find((obj: { name: string }) => obj.name.toLowerCase().trim() === tipo.toLowerCase().trim())
            if (!inv) throw Error(`No se encontro el inventario ${tipo}`)
            return { tin_amount, tin_fk_type: inv.id }
          })
          const tvd_folio = destino.folio_destino

          return {
            tvd_fk_client,
            tvd_plataform_route_origin,
            tvd_plataform_route_destination,
            tvd_estimated_leave_date,
            tvd_estimated_arrival_date,
            tvl_inventory,
            tvd_route,
            tvd_details: '',
            tvd_folio,
            type
          }
        })),
        tra_details: data.detalles || ''
      }
    }))

    await dispatch('createMany', travelsPayload)
  },
  lockTravel ({ rootState }: ActionContext<State, any>, { images, tra_id }: { images: any; tra_id: number }) {
    const index = rootState.resources[RESOURCES.TRAVELS].findIndex(
      // @ts-ignore
      (tvl) => tvl.travel_id === tra_id
    )
    const travel = rootState.resources[RESOURCES.TRAVELS][index]
    const imgs = images.data.img
    travel.tra_locked = true
    travel.fileLoad = imgs
  },
  async updateLocks (
    { rootState, commit, dispatch }: ActionContext<State, any>,
    travelPayload: any
  ) {
    const index: number = rootState.resources[RESOURCES.TRAVELS].findIndex(
      (tra: { travel_id: number }) => tra.travel_id === travelPayload.tra_id
    )
    if (index !== -1) {
      commit(
        'resources/UPDATE_RESOURCE',
        { index, rType: RESOURCES.TRAVELS, item: travelPayload, force: false },
        { root: true }
      )
    }
    const travel = rootState.resources[RESOURCES.TRAVELS][index]
    travel.transport_line_resource_id = travel.tra_plataform_line_resource_id
    await dispatch('susbscribeAndVerify', travel)
  },
  async delete (
    { rootState, commit, dispatch }: ActionContext<State, any>,
    travelPayload: any
  ) {
    await dispatch(
      'app/axios',
      {
        url: '/apis/cancel_travel/',
        method: 'POST',
        data: { trav_id: travelPayload.travel_id }
      },
      { root: true }
    )
    // @ts-ignore
    const index: number = rootState.resources[RESOURCES.TRAVELS].findIndex(
      (tra: { travel_id: number }) => tra.travel_id === travelPayload.travel_id
    )
    if (index !== -1) {
      commit(
        'resources/REMOVE_RESOURCE',
        { index, rType: RESOURCES.TRAVELS },
        { root: true }
      )

      await dispatch('loadTravels')
    }
  },
  async create ({ dispatch }: ActionContext<State, any>, travel: any) {
    await dispatch(
      'app/axios',
      {
        url: '/apis/create_travel_resource/',
        method: 'POST',
        data: travel
      },
      { root: true }
    )
    await dispatch('susbscribeAndVerify', travel)
    await dispatch('loadTravels')
    await dispatch('getWialonResourcesIdsAndUnits')
    // await dispatch('addNewResourceNotificationUnit', data.data.data)
  },
  update (
    { rootState, commit }: ActionContext<State, any>,
    travelPayload: Travel
  ) {
    // @ts-ignore
    const index: number = rootState.resources[RESOURCES.TRAVELS].findIndex(
      (tra: { travel_id: number }) => tra.travel_id === travelPayload.tra_id
    )
    if (index !== -1) {
      commit(
        'resources/UPDATE_RESOURCE',
        { index, rType: RESOURCES.TRAVELS, item: travelPayload },
        { root: true }
      )
    }
  },
  async uploadFiles (
    { dispatch }: ActionContext<State, any>, payload: any) {
    const formData = objToFormData(payload)
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/upload_file/',
        method: 'POST',
        data: formData
      },
      { root: true }
    )

    return data
  },
  async downdloadStatusTravel ({ dispatch, rootState, rootGetters }: ActionContext<State, any>) {
    // @ts-ignore
    const travelsJson = rootState.resources[RESOURCES.TRAVELS].map((resource) => {
      const nameDestiny = rootGetters['travel/getNameDestiny'](resource)
      const nameOrigin = rootGetters['travel/getNameOrigin'](resource)
      const { date_registration, tra_estado_travel, tra_folio, tra_name, tra_plataform, tra_plataform_unit: { name } } = resource
      const unit_name = name
      const status = rootGetters['travel/getStatusTravelString'](tra_estado_travel)
      const geofence = (tra_estado_travel === ESTATUS_TRAVEL.CARGANDO_EN) ? nameOrigin : (tra_estado_travel === ESTATUS_TRAVEL.DESCARGANDO_EN) ? nameDestiny : '----'

      return {
        date_registration, status, tra_folio, tra_name, tra_plataform, unit_name, nameOrigin, nameDestiny, geofence
      }
    })
    const data = [
      {
        sheet: 'Informe',
        columns: [
          { label: 'Fecha de registro', value: 'date_registration' },
          { label: 'Viaje', value: 'tra_name' },
          { label: 'Folio', value: 'tra_folio' },
          { label: 'Unidad', value: 'unit_name' },
          { label: 'Estado del viaje', value: 'status' },
          { label: 'Geocerca actual', value: 'geofence' }
        ],
        content: travelsJson
      }
    ]
    const settings = {
      fileName: 'Informe de viajes', // Name of the resulting spreadsheet
      extraLength: 3, // A bigger number means that columns will be wider
      writeOptions: {} // Style options from https://github.com/SheetJS/sheetjs#writing-options
    }
    xlsx(data, settings) // Will download the excel file
  },
  async calculateRoute ({ dispatch, rootState, rootGetters }: ActionContext<State, any>, payload:any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/recalculate-route/',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    return data
  },
  async createIncident ({ dispatch }: ActionContext<State, any>, payload: any) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: '/apis/create_incident/',
          method: 'POST',
          data: payload
        },
        { root: true }
      )
      await dispatch(
        'app/notification',
        {
          content: 'Incidente registrado',
          type: 'success',
          timeout: 3000
        },
        { root: true }
      )
      return data
    } catch (error) {
      throw new Error('Error al registrar Incidencia: ')
    }
  }
}
export default actions
