import { State } from './state'

export default {
  SET_ALL (state: State, items: []) {
    state.sales = items
  },
  UPDATE (state: State, item: any) {
    const sales = state.sales.find(sale => sale.id === item.id)
    // @ts-ignore
    Object.assign(sales, { ...item })
  },
  PUSH (state: State, item: any) {
    state.sales.push(item)
  },
  DELETE (state: State, item: any) {
    state.sales = state.sales.filter(sale => sale.id !== item.id)
  }
}
