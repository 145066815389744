export default function download (url: string, filename: string) {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = filename
      link.click()
    })
    .catch(console.error)
}

export function downloadData (filename: string, text: string, type = 'text/plain') {
  const element = document.createElement('a')
  element.setAttribute('href', `data:${type};charset=utf-8,` + encodeURIComponent(text))
  element.setAttribute('download', filename)

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()

  document.body.removeChild(element)
}

export function downloadByBlob (blob: Blob, filename: string) {
  const imgURL = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = imgURL
  link.download = filename
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
