// src/utils/notifications.js
import Vue from 'vue'
import VueNotification from '../components/utils/VueNotification.vue'
// import CustomNotification from '@/components/CustomNotification.vue' // Asegúrate de que la ruta sea correcta

// @ts-ignore
export function notifyToast ({ msj, type, title = 'Notificación de operación' }) {
  Vue.notify({
    group: 'app',
    type,
    title: title,
    text: msj,
    duration: 5000,
    data: {
      date: new Date().toLocaleString()
    }
  })
}
