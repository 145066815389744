
export interface CRMState {
  sales: any[]
}

const state = (): CRMState => ({
  sales: []
})

export type State = ReturnType<typeof state>

export default state
