/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ActionContext } from 'vuex/types/index'
import { State } from './state'
import JimiIothubSDK from '@/submodules/iothubsudsdk/dist'
import { IJimiIotDVRLastStatus } from '@/interfaces/JimiIot.interfaces'
import strRangeToNumbers from '@/utils/strRangeToNumbers'
import Camera from '@/classes/Camera/Camera'
import UnitConsoleData from '../packages/ReportItem/ReportItemData'
import { CAMERA_TYPE } from '../catalogs/CAMERA_TYPE'
import { ET_JIMIIOT_ADAS, ET_JIMIIOT_DMS, TYPE_NOTIFY, EVENTS_TYPES_JIMIIOT, DriverEvent } from '../catalogs/EVENT_TYPES_JIMIIOT'
import Vue from 'vue'
import { CAMERA_PLATAFORM } from '../catalogs/CAMERA_PLATAFORM'
import Notification, { INotification } from '@/classes/Notification/Notification'
import IPushAlarm from '@/submodules/iothubsudsdk/dist/interfaces/Events/PushAlarm.interface'
import { DateTime } from 'luxon'
import { IStandarAlarmFieldValue } from '@/submodules/iothubsudsdk/dist/alarms/STANDAR_ALARM_FIELDS'

export default {
  async init ({ dispatch }: ActionContext<State, string>) {
    await dispatch('initSDK')
    await dispatch('subscribeJimiImeisNotifications')
  },
  initSDK ({ commit, rootState }: ActionContext<State, string>) {
    const sdk = new JimiIothubSDK({
      iothubServerIp: process.env.VUE_APP_JIMI_IOT_SERVER_HOST!,
      iothubCustomerServerInterfaceHost: process.env.VUE_APP_JIMI_IOT_CUSTOM_SERVER!,
      // @ts-ignore
      useHttps: true,
      // @ts-ignore
      debug: rootState.app.IS_DEV,
      usePortsAsUrl: true
    })
    commit('SET_STATE', { sdk })
  },
  async getDevicesStatusByIds ({ state }: ActionContext<State, string>, imeis: string[]): Promise<IJimiIotDVRLastStatus> {
    if (!state.sdk) return {}

    const { data } = await state.sdk.dvr.get_dvr_upload_jc450_status_last([], {
      imeis
    })

    return data
  },
  async getVideoChannelsJimiiot ({ rootState, state }: ActionContext<State, string>, payload: {
    camera: Camera;
    unitconsole: UnitConsoleData<unknown, unknown>;
    currChannels: any[];
  }) {
    const channels = strRangeToNumbers(payload.camera.cpt_properties.channels)
    const allowedAudioChannels = new Set(strRangeToNumbers(payload.camera.cpt_properties.allowedAudioChannels))
    const channelsLinks = state.sdk?.dvr.getChannelsLinks(payload.camera.cpt_properties.imei, channels)!

    const cameras = channelsLinks.map(({ channelId, link }) => ({
      id_camera: `${channelId}`,
      cam_name: `${channelId}`,
      cam_type: CAMERA_TYPE.FLV,
      cam_url: link,
      cam_edit: false,
      cam_delete: false,
      cam_data: {
        allowedAudioChannels
      },
      device: null,
      fk_camera_properties: 0
    }))

    // @ts-ignore
    const unitConsoleData: UnitConsoleData<unknown, unknown> = rootState.travel.unitsRegisters.find((obj: UnitConsoleData<unknown, unknown>) => obj.id === payload.unitconsole.id)
    if (unitConsoleData) {
      unitConsoleData.setCameraChannels(cameras)
    }
  },
  toggleAllowAllNotifications ({ state, getters }: ActionContext<State, string>) {
    if (!state.disabledNotification) return

    const areAllNotificationsActive = getters.areAllNotificationsActive
    for (const key in ET_JIMIIOT_ADAS) {
      Vue.set(state.notifications_active[TYPE_NOTIFY.ADAS], key, !areAllNotificationsActive)
    }

    for (const key in ET_JIMIIOT_DMS) {
      Vue.set(state.notifications_active[TYPE_NOTIFY.DMS], key, !areAllNotificationsActive)
    }
  },
  subscribeJimiImeisNotifications ({ state, rootGetters, commit, rootState, dispatch }: ActionContext<State, string>) {
    // @ts-ignore
    const IS_DEV = rootState.app.IS_DEV

    if (IS_DEV) console.log('subscribeJimiImeisNotifications', state.sdk)
    if (!state.sdk) return

    if (IS_DEV) console.log('currSubscribedId', state.currSubscribedId)
    if (state.currSubscribedId) {
      state.sdk.events.dataPushEvents.unsubscribeToPushEvents(state.currSubscribedId)
    }

    // @ts-ignore
    const unitWithCameras: {
        camera: Camera | null;
        unitconsole: UnitConsoleData<unknown, unknown>;
        currChannels: never[];
    }[] = rootGetters['travel/getUnitsWithCamerasOnly']

    const unitsWithJimiCameras = unitWithCameras.filter(obj => obj.camera?.cpf_type === CAMERA_PLATAFORM.JIMIIOT)
    if (!unitsWithJimiCameras.length) return

    // @ts-ignore
    const jimiCamerasImeis: string[] = unitsWithJimiCameras
      .filter(obj => obj.camera?.cpt_properties?.imei)
      .map(obj => obj.camera?.cpt_properties?.imei)

    if (IS_DEV) console.log('jimiCamerasImeis', jimiCamerasImeis)

    const currSubscribedId = state.sdk.events.dataPushEvents.subscribeToPushEvents(jimiCamerasImeis, 'pushalarm', async (imeiEvents) => {
      if (!state.disabledNotification) return

      const unitConsole = unitsWithJimiCameras.find(obj => obj.camera?.cpt_properties?.imei === imeiEvents.imei)
      if (IS_DEV) console.log('unitConsole', unitConsole)
      if (!unitConsole) return

      for (let index = 0; index < imeiEvents.events.length; index++) {
        const event: IPushAlarm = imeiEvents.events[index]

        // @ts-ignore
        const typeEvent: TYPE_NOTIFY | undefined = Object.values(TYPE_NOTIFY).find(obj => String(obj) === String(event.msg.alertType))
        if (IS_DEV) console.log('typeEvent', typeEvent)
        if (!typeEvent) continue

        // @ts-ignore
        const jimiNotifications: DriverEvent[] = await dispatch('getNotificationsDriverEvent', { typeEvent, event })
        if (IS_DEV) console.log('jimiNotification', jimiNotifications)
        if (!jimiNotifications.length) continue

        for (let index = 0; index < jimiNotifications.length; index++) {
          const jimiNotification = jimiNotifications[index]

          const isEnabled = state.notifications_active[typeEvent][jimiNotification.type]
          if (IS_DEV) console.log('isEnabled', isEnabled)
          if (!isEnabled) continue

          const notify: INotification = {
            id_unit: unitConsole.unitconsole.id,
            unit_name: unitConsole.unitconsole.unit_name,
            cam_name: unitConsole.camera?.cpf_name,
            cam_id: imeiEvents.imei,
            notification: jimiNotification.name,
            message: jimiNotification.getNotName(),
            txt: jimiNotification.getNotMessage(),
            // @ts-ignore
            date: DateTime.fromFormat(event.msg.alarmTime, 'yyyy-MM-dd HH:mm:ss').plus({ hours: rootState.app.timeoffset }).toFormat('dd/MM/yyyy - HH:mm'),
            icon: jimiNotification.icon,
            color: jimiNotification.color,
            urlMaps: `http://maps.google.com/?q=${event.msg.lat},${event.msg.lng}`
          }

          // @ts-ignore
          for (let index = 0; index < rootState.cameras.notifications.length; index++) {
            // @ts-ignore
            const elementNotify: Notification = rootState.cameras.notifications[index]
            const isSameUnit = elementNotify.id_unit === notify.id_unit
            const isSameTypeNotify = elementNotify.notification === notify.notification
            const isSameCamID = elementNotify.cam_id === notify.cam_id
            if (isSameTypeNotify && isSameUnit && isSameCamID) {
              elementNotify.notification_child?.unshift(new Notification(notify))
              if (!state.disabledSoundNotification) break
              dispatch('app/playSound', { type: 'camera_alert' }, { root: true })
              break
            }
          }
          // @ts-ignore
          rootState.cameras.notifications.unshift(new Notification(notify))
          if (!state.disabledSoundNotification) continue
          dispatch('app/playSound', { type: 'camera_alert' }, { root: true })
        }
      }
    })

    commit('SET_STATE', { currSubscribedId })
  },
  async getNotificationsDriverEvent ({ state, dispatch }: ActionContext<State, string>, { typeEvent, event }: { typeEvent: TYPE_NOTIFY; event: IPushAlarm }): Promise<DriverEvent[]> {
    if (!EVENTS_TYPES_JIMIIOT[typeEvent] || !state.sdk) return []

    if (typeEvent === TYPE_NOTIFY.ADAS || typeEvent === TYPE_NOTIFY.DMS) {
      // @ts-ignore
      const jimiNotification: DriverEvent | undefined = EVENTS_TYPES_JIMIIOT[typeEvent][event.msg.alarmType ?? -1]
      return jimiNotification ? [jimiNotification] : []
    } else if (typeEvent === TYPE_NOTIFY.STANDARD) {
      const bitsEvents: IStandarAlarmFieldValue[] = await dispatch('getDiffStandarValueAlarms', { imei: event.imei, value: event.msg.standardAlarmValue })
      const jimiNotifications: DriverEvent[] = bitsEvents.reduce((acc, alert) => {
        // @ts-ignore
        const jimiNotification = EVENTS_TYPES_JIMIIOT[typeEvent][alert.bit_value]
        if (!jimiNotification) return acc

        acc.push(jimiNotification)
        return acc
      }, [] as DriverEvent[])

      return jimiNotifications
    }

    return []
  },
  async getDiffStandarValueAlarms ({ state, commit, rootState }: ActionContext<State, string>, { imei, value }: { imei: string; value: number }): Promise<IStandarAlarmFieldValue[]> {
    if (!state.sdk || value === state.lastDevicesStandarValues[imei]) return []
    // @ts-ignore
    const IS_DEV = rootState.app.IS_DEV
    const currBitsEvents: Set<number> = state.lastDevicesStandarValues[imei] ? new Set(state.sdk.events.dataPushEvents.getStandarAlertsByValue(state.lastDevicesStandarValues[imei]).alerts.map((obj) => obj.bit_value)) : new Set()
    const incomingBitsEvents = state.sdk.events.dataPushEvents.getStandarAlertsByValue(value).alerts
    const diffBitsEvents = incomingBitsEvents.filter((curr) => !currBitsEvents.has(curr.bit_value))
    if (IS_DEV) console.log('currBitsEvents', currBitsEvents, 'incomingBitsEvents', incomingBitsEvents, 'diffBitsEvents', diffBitsEvents)
    // if (IS_DEV) console.log(`currBitsEvents ${currBitsEvents}; incomingBitsEvents ${incomingBitsEvents}; diffBitsEvents ${diffBitsEvents}`)
    commit('SET_STATE', { lastDevicesStandarValues: { ...state.lastDevicesStandarValues, [imei]: value } })
    return diffBitsEvents
  }
}
